import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { clearCookie, getCookie, setCookie } from '../utilities/cookies';
//import axios from 'axios';
//import { useMessage } from '../components/Header';

const authorizeContext = createContext();

const AuthorizationProvider = ({ children }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [content, setContent] = useState(children);
    const [user, setUser] = useState({});
    const [redirectUrl, setRedirectUrl] = useState("");
    const [referrer, setReferrer] = useState("");
    const hideAutoLogin = ["/logout"]; 

    const hasQueryParams = (redirectUrl) => {
        if( !redirectUrl || redirectUrl == '' || redirectUrl == 'null' || redirectUrl == null) return "" ;
       
        const urlObj = new URL(redirectUrl);
        let hasQuery =   urlObj.searchParams.toString() !== '';
        console.log("hasQuery ", hasQuery)
         return redirectUrl+ ( hasQuery ?  "&&" : "?" )
      };
     
      // Example usage

    const  autoSessionCheck = async(redirect) =>{
        let session =  getCookie("user");
        if(session && session.success){
            try {
                setContent( <Loading message='Please wait, logging you in...' />)
                let formData = new FormData();
                formData.append("sessionToken", session.sessionToken);
                formData.append("userId", session.user._id);
                const response = await fetch(
                  process.env.REACT_APP_BASE_URL+"/api/auth/session/login",
                  // "http://localhost:5000/api/auth/session/login",
                  {
                    method: "POST",
                    body: formData,
                  },
          
                );
                const responseData = await response.json();
                if (responseData.success) {
                  setCookie("user", responseData);
                  const { user , refreshToken } = responseData;
                  if (redirect && redirect !== '' && redirect !== 'null') {
                    return window.location.replace(redirect + "userId=" + user._id+"&refreshToken="+refreshToken);
                  }  else {
                    window.location.replace('https://apps.clikkle.com/');
                  }
                }else {
                    throw Error("you are logout ")
                } 
              } catch (error) {
                console.error("Error:", error);
                setContent(children)
                clearCookie("user")
              }
        }
    }

    // const [refreshToken, setRefreshToken ] = useState("");

    
    useEffect(() => {
        (async () => {
            try {
               let stopAuto = hideAutoLogin.includes(location.pathname);

               console.log(stopAuto , " stopAuto")
               
      
                //  const loggedInUserEmail = getCookie('loggedInUserEmail');
                const queryParameters = new URLSearchParams(window.location.search);
                let redirectTo = queryParameters.get("redirectto");
                let referrer = queryParameters.get("referrer");

                if(referrer){
                  console.log(referrer,"referrer")
                  setReferrer(referrer)
                }else {
                  const referrerURL = document.referrer;
                  console.log(referrerURL,"referrerURL")
                  setReferrer(referrerURL)
                }
                // if(redirectTo ==null) redirectTo=""
                console.log(redirectTo , "redirectTo")
                redirectTo = hasQueryParams(redirectTo) ;
                setRedirectUrl(redirectTo);
             if(!stopAuto){
                 await autoSessionCheck(redirectTo);
                console.log("redirectTo  url  store" , redirectTo)
              }
            } catch (err) {
                console.log(err);
                // handleAxiosError(err, showError);
                // authorize(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <authorizeContext.Provider value={{redirectUrl, setRedirectUrl , user, setUser ,referrer }}>
            {content}
        </authorizeContext.Provider>
    );
};

// const useAuthorize = () => useContext(authorizeContext).authorize;
// const useUser = () => useContext(authorizeContext)?.user;
// const useSetUser = () => useContext(authorizeContext).setUser;
// const useSetContent = () => useContext(authorizeContext).setContent;
// const useEmployees = () => useContext(authorizeContext)?.employees;

const useRedirectUrl = () => useContext(authorizeContext).redirectUrl;
const useUser = () => useContext(authorizeContext)?.user;
const useSetUser = () => useContext(authorizeContext)?.setUser;
const useReferrer = () => useContext(authorizeContext)?.referrer;


export default AuthorizationProvider;
export { useRedirectUrl , useUser ,useSetUser ,useReferrer }
// export { useAuthorize, useUser, useSetUser, useSetContent, useEmployees };
