import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useRedirectUrl } from "../../hooks/Authorize";
import useWidth from "../../hooks/useSize";
import { useNavigate } from "react-router-dom";
import { clearCookie, getCookie, setCookie } from "../../utilities/cookies";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

const Logout = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const redirectto = queryParameters.get("redirectto")
  const width = useWidth();
  const navigate = useNavigate();

  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = async () => {
    let session = getCookie("user");
    if (session && session.success) {
      try {
        let formData = new FormData();
        formData.append("sessionToken", session.sessionToken);
        formData.append("userId", session.user._id);

        const response = await fetch(
          process.env.REACT_APP_BASE_URL+"/api/auth/logout",
          // "http://localhost:5000/api/auth/logout",
          {
            method: "POST",
            body: formData,
          }
        );

        const responseData = await response.json();

        if (responseData.success) {
          clearCookie("user");
          // redirectto && redirectto !=='' ? navigate("/login"+"?redirectto="+ redirectto) : navigate("/login");
          redirectto && redirectto !=='' ? window.location.href=redirectto : navigate("/login");
        } else {
          toast.error(responseData.error);
          console.error(responseData.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      redirectto && redirectto !=='' ? window.location.href=redirectto : navigate("/login");
    }
  };

  const styles = {
    loginWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      height: "100dvh",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    loginContent: {
      maxWidth: width > 768 ? "448.8px" : "100%",
      height: width > 768 ? "500px" : "100%",
      border: width > 768 ? "1px solid #8C8C8C" : "none",
      borderRadius: width > 768 ? "12px" : "none",
      // boxShadow:
      //   width > 768 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "none",
      px: "35px",
      py: "35px",
      backgroundColor: "#121212",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formWrapper: {
      paddingTop: "30px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignContent: "space-between",
      flexDirection: "column",
    },
    heading: {
      fontSize: "24px",
      fontWeight: 600,
    },
    label: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#f5f4f4",
      py: 1,
    },
    emailInputAdornment: {
      fontSize: "13px",
      color: "#605f5f",
      fontWeight: 600,
      letterSpacing: 0.8,
      pt: 0.5,
    },
    forgotPassword: {
      textDecoration: "none",
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
    },
    register: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
      textDecoration: "none",
    },
    button: {
      backgroundColor: "#1472b8",
      padding: "6px 16px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "uppercase",
      "&:hover": {
        background: "#1472b8",
      },
    },
    desc: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#7e7878",
      py: 1,
      px: 2,
    },
    avatarWrapper: {
      display: "flex",
      alignItems: "center",

      gap: "10px",
    },
  };

  return (
    <Box sx={styles.loginWrap}>
      <Box
        style={{
          textAlign: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Loading message="Please wait while we log you out..." />
        {/* <Typography component="p" sx={styles.desc}>
          Enter the AI realm with us, or join us with your login
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Logout;
